<template>
  <v-card outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <v-list-item-title class="headline">Route info</v-list-item-title>
        <v-list-item-subtitle >{{subtitle}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <v-row>
        <v-col class="display-3 success--text">
          {{distance || 0}}
        </v-col>
      </v-row>
    </v-card-text>
    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-timer</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{duration || 0}}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
        <v-menu
          ref="menu"
          v-model="menuDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @input="(d) => $emit('update:date', d)"
              :value="date"
              label="Start date"
              prepend-icon="mdi-calendar"
              dense
              flat
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :value="date"
            no-title
            scrollable
            @input="(d) => {
              $emit('update:date', d);
              menuDate = false;
            }"
            :min="new Date().toISOString()"
          />
        </v-menu>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-text-field hide-details="auto" :value="link" color="grey" readonly label="Copy route link" id="route-link" ref="route-link" outlined dense>
          <template v-slot:append>
            <v-btn icon x-small @click="copyLink(link, 'route-link')" title="copy" :disabled="!link">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="eventLink">
      <v-list-item-content>
        <v-text-field hide-details="auto" :value="eventLink" color="grey" readonly label="Copy event link" id="event-link" ref="event-link" outlined dense>
          <template v-slot:append>
            <v-btn icon x-small @click="copyLink(eventLink, 'event-link')" title="copy" :disabled="!eventLink">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-btn depressed block color="success" @click="apply" :loading="loading" :disabled="disabledSave">Confirm</v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-btn depressed block color="primary" @click="saveEvent" :disabled="disabledSaveToCalendar" :loading="loading">
        {{routeChanged ? 'Confirm before or reset' : 'Save to Google Calendar'}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import { userTasksApi, tasksApplyApi } from '@/api';
import { googleAuth} from '@/services/auth';

export default {
  props: {
    info: Array,
    selected: Array,
    link: String,
    date: String,
    loading: Boolean,
    savedTasks: Array,
  },
  data() {
    return {
      // savedTasks: [],
      // loading: false,
      // date: this.$moment().format('YYYY-MM-DD'),
      menuDate: false,
      eventLink: '',
      saved: [],  /* selected tasks which where saved*/
      savedTasksApi: [],
    }
  },
  computed: {
    ...mapState(['user', 'userToken', 'apiFilters', 'apiTasks']),
    subtitle() {
      const start = this.user.location?.address || '';
      const end = start;
      let route = this.selected.map((el) => el.location).join(' > ');
      route = route ? `${route} >` : ''
      return `${start} > ${route ? route : ''} ${end}`;
    },
    distance() {
      let distance = '';
      distance = this.info.length ? this.info.reduce((acc, val) => acc + val.distance.value, 0) : 0;
      return this.distanceToString(distance);
    },
    duration() {
      let duration = '';
      duration = this.info.length ? this.info.reduce((acc, val) => acc + val.duration.value, 0) : 0;
      return this.$moment.duration(duration, "seconds").format("h _, m _");
    },
    disabledSave() {
      const { add, remove } = this.getNewAndOld();
      let disableButton=false;
      this.selected.map(function(task) { 
        if(!task.station){
          disableButton=true;
        }
           if(!task.station.latitude){
          disableButton=true;
        }
            if(!task.station.longitude){
          disableButton=true;
        }
        return task;
      });
      return (add.length === 0 && remove.length === 0) || this.loading || disableButton;
    },
    disabledSaveToCalendar() {
      return this.savedTasks.length === 0 || this.loading || this.routeChanged;
    },
    routeChanged() {
      const { add, remove } = this.getNewAndOld();
      return (add.length !== 0 || remove.length !== 0);
    },
  },
  watch: {
    date: {
      immediate: true,
      handler() {
       
        this.getUserTasks();
      },
    },
  },
  methods: {
    distanceToString(val) {
      let result = '';
      if (val >= 1000) {
        result = `${Math.round(val / 100) / 10} km`;
      } else {
        result = `${val} m`;
      }
      return result;
    },
    copyLink(link, ref) {
      if ('navigator' in window && 'clipboard' in window.navigator) {
        navigator.clipboard.writeText(this.$refs[ref].value)
          .then(() => this.$store.dispatch('add_message', {message: 'Copied!', color: 'success', timeout: 2000}));
      } else {
        const el = document.getElementById(ref);
        // el.focus();
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand("copy");
        this.$store.dispatch('add_message', {message: 'Copied!', color: 'success', timeout: 2000});
      }
    },
    getNewAndOld() {
         
      const selected = this.selected.map((task) => task.id);
 
      const saved = this.savedTasks.map((task) => task.id);
      const add = this.$lodash.difference(selected, saved);
 
      const remove = this.$lodash.difference(saved, selected);
     
      return { add, remove };
    },
    apply() {
      // apply tasks to user
      if (this.loading) return;
      this.$emit('update:loading', true);
      const { add, remove } = this.getNewAndOld();
      const date = this.$moment(this.date).format('MM/DD/YYYY');
     
      const arraySize=this.info.length-1;
      const selectedItems=this.selected;
      var start;
      var end;
      var distance=0;
      this.info.forEach(function(currentValue, index, arr){
        if(index===0){
    //start
          start={lat:currentValue.start_location.lat(),lng:currentValue.start_location.lng()};
        }   
        

        if(index===arraySize){
//ende
            end={lat:currentValue.end_location.lat(),lng:currentValue.end_location.lng()};
        }//else{
           
        //  route.push({distance:currentValue.distance.value,lat:currentValue.end_location.lat(),lng:currentValue.end_location.lng()});
        
        //}
        distance+=currentValue.distance.value;
      });

 const route={start:start,end:end};
const allTasks=this.selected;
      const params = {
        add,
        remove,
        date,
        route,
        allTasks,
      };
     // console.log(JSON.stringify(route)); 
       //     console.log(JSON.stringify(allTasks)); 
//route:[{lat,lng,distance,start},{taskId:0,lat,lng,distance},{lat,lng,distance,stop}]
      if (add.length === 0 && remove.length === 0) {
        const message = 'Keine Daten zu aktualisieren';
        this.$store.dispatch('add_message', {message, color: 'error', infinity: true, button: 'ok'});
        this.$emit('update:loading', false);
        return
      }
      if (!route.start.lat) {
        const message = 'Kein Start ausgewählt';
        this.$store.dispatch('add_message', {message, color: 'error', infinity: true, button: 'ok'});
        this.$emit('update:loading', false);
        return
      }

      if (!route.end.lat) {
        const message = 'Kein Ende ausgewählt';
        this.$store.dispatch('add_message', {message, color: 'error', infinity: true, button: 'ok'});
        this.$emit('update:loading', false);
        return
      }

      tasksApplyApi(params)
        .then((res) => {
          // store saved tasks
          this.saved = [...this.selected];

          // remove from store this tasks
          // const newTasks = this.$lodash.omit(this.apiTasks, this.selected.map((el) => el.id));
          // console.log(this.selected.map((el) => el.id))
          // this.$store.commit('SET_API_TASKS', newTasks);

          // call update tasks
          this.$store.commit('SET_FILTERS', { ...this.apiFilters });

          // call saved tasks
          this.getUserTasks()
        })
        .catch((err) => {
          let tasksError = [];
          const errors = Object.entries(err.response?.data?.errors || {});
          if (errors.length) {
            errors.forEach(error => {
              let message = `${error[1].join(' ')}`;
              if (!!Number.parseInt(error[0])) {
                const item = this.selected.filter((el) => Number.parseInt(el.id) === Number.parseInt(error[0]))[0];
                const location = item ? item.location : '';
                const description = item ? item.description : '';
                message += `
                  <div class="mt-3">#${error[0]} - <strong>${location}</strong></div>
                  <div class="caption">${description}</div>
                `
              }
              this.$store.dispatch('add_message', {message, color: 'error', infinity: true, button: 'ok'});
            });
          } else {
            this.$store.dispatch('add_message', {message: err, color: 'error', infinity: true, button: 'ok'});
          }
        })
        .then(() => this.$emit('update:loading', false));
    },
       saveEvent() {
          // save to google calendar
      if (!global.window.gapi) {
        console.log('gapi not inited');
        return;
      }
       let tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: googleAuth.clientId,
          scope: googleAuth.scope,
          callback: '', // defined later
        });
        let me=this;
      tokenClient.callback = async (resp) => {
          if (resp.error !== undefined) {
            throw (resp);
          }
            me.saveEventImpl();
      };
          if (gapi.client.getToken() === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
         // console.log('consent');
          tokenClient.requestAccessToken({prompt: ''});
        } else {
          this.saveEventImpl();
        }


    

      // request.execute(function(event) {
      //   console.log('Event created: ' + event.htmlLink);
      // });
       },
    saveEventImpl() {
     
      const date = this.date;
      const grouped = Object.entries(this.$lodash.groupBy(this.saved, 'location'));
      const summary = `«UPLINK Route»: ${grouped.map(([location]) => location).join(' - ')}`;
      const description = `\
Ihre Route: ${this.link}\n
${grouped.map(([loc, stas]) => `${loc}
${stas.map((sta) => `* ${sta.description}`).join('\n')}`).join('\n\n')}`;


 
            window.gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        'resource': {
          summary,
          description,
          start: { date },
          end: { date },
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.eventLink = res.result.htmlLink;
            this.$store.dispatch('add_message', {message: 'Event saved to google calendar', color: 'success', infinity: true, button: 'ok'});
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
   

  
    },
    async getUserTasks() {
      // get user tasks for selected date
      this.$emit('update:loading', true);
      // remove savedtasks from selected
     // this.$emit('update:selected', this.$lodash.differenceBy(this.selected, this.savedTasks, 'id'));

      let savedTasks = [];
      let res = null;
      const params = {
        date: this.$moment(this.date).format('MM/DD/YYYY'),
      }

      try {
        res = await userTasksApi(params);
       // console.log(res.data);
        savedTasks = Object.keys(res.data).map((k) => ({
          id: k,
          ...res.data[k],
        }));
      } catch (error) {
        if (!error.response.status === 404) {
          console.log(error);
        }
      }
 
      this.$emit('update:savedTasks', savedTasks);
      this.$emit('update:selected', this.$lodash.unionBy([...this.selected, ...savedTasks], 'id'));
      this.$emit('updateRoute');
      this.$emit('update:loading', false);
    },
  },
}
</script>
