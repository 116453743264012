<template>
  <v-img :aspect-ratio="16/9">
    <div id="map"></div>
  </v-img>
</template>
<script>
import { mapState } from 'vuex';
import places from '@/mixins/places';

export default {
  mixins: [places],
  props: {
    tasks: Array,
  },
  data() {
    return {
      defaultLocation: {
        lng: 13.404954,
        lat: 52.520008,
      }
    }
  },
  computed: {
    ...mapState(['apiFilters', 'apiAvailableFilters', 'user', ]),
    LatLng() {
      return {
        lat: Number.parseFloat(this.user.location?.latitude) || this.defaultLocation.lat,
        lng: Number.parseFloat(this.user.location?.longitude) || this.defaultLocation.lng,
      }
    },
    markers() {
 
      let markers = {
        type: "FeatureCollection",
        features: [],
      };
      if (!this.tasks.length) return markers;
      const features = this.tasks.filter(function (task) {
  return  task.station &&  task.station.longitude &&  task.station.latitude;
}).map((task) => {
        return {
          type: 'Feature',
          properties: {
            type: task.priority,
          },
          id: task.id,
          geometry: {
            type: 'Point',
            coordinates: [
              task.station.longitude,
              task.station.latitude,
            ]
          },
        }
      })
      markers.features = features;
      return markers;
    },
  },
  watch: {
    LatLng(latLng) {
      this.setPos(latLng);
    },
    markers() {
  //console.log('watch tasks');
      this.setMarkers();
 
    },
  },
  mounted() {
    this.loadMapScript()
      .then(() => {
        this.initMap();
 
         this.setMarkers();
     
        this.initDirections();
      })

    // handle event
    this.$on('google.data.click', (feature) => {
      const task = this.tasks.filter((el) => Number.parseInt(el.id) === Number.parseInt(feature.getId()))[0];
      this.$emit('pan-to', task);
    })
  }
}
</script>
<style lang="scss">
  #map {
    height: 100%;
  }
</style>
