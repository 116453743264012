<template>
  <v-container class="fill-height">
    <v-row v-if="isAuthorized">
      <v-col cols="12" sm="4">
        <TaskList   :userStart.sync="userStart" :tasks="unselectedTasks" @add="add" @pan-to="(task) => panToTask(task)"/>
        <RouteInfo
          v-if="routeInfo"
          class="mt-3"
          :info="routeInfo"
          :selected.sync="selected"
          :date.sync="date"
          :link="routeLink"
          :loading.sync="routeInfoLoading"
          @updateRoute="calcRoute"
          :savedTasks.sync="savedTasks"
        />
      </v-col>
      <v-col cols="12" sm="8">
        <Map :tasks="tasksGrouped" ref="map" @pan-to="(task) => panToTask(task)" />
        <TaskSelected
          class="mt-3"
          :selected.sync="selected"
          :userStart.sync="userStart"
          :date="date"
          :userDestination.sync="userDestination"
          @update:selected="calcRoute"
          @del="del"
          :optimized.sync="optimized"
          @pan-to="(task) => panToTask(task)"
          @add-location-task="addLocationTask"
          @calc-route="calcRoute"
          :loading="routeInfoLoading"
          :routeOrder="routeOrder"
        />
      </v-col>
    </v-row>
    <v-row class="screen-height" v-if="estimate && estimate.asSeconds() === 0">
      <v-col class="text-center">Sie wurden automatisch ausgeloggt</v-col>
    </v-row>
    <v-bottom-sheet v-model="sheet" inset no-click-animation scrollable>
      <v-card>
        <v-toolbar outlined flat dense v-if="subTasks[0]" >
          <v-icon>mdi-map-marker</v-icon>
          <v-toolbar-title>{{`${subTasks[0].location}`}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="sheet = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense>
            <v-list-item two-line link v-for="(task, i) in subTasks" :key="i" @click="toggle(task)">
              <v-list-item-avatar size="24">
                <v-avatar class="white--text" :color="taskColorMap[task.priority]">{{task.priority}}</v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{task.description}}</v-list-item-title>
                <v-list-item-subtitle class="text-wrap" v-if="task.comments"><v-icon small class="mr-2">mdi-chat</v-icon>{{task.comments}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action small>
                <v-btn v-if="selected.some((el) => el.id === task.id)" icon color="error" small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn v-else icon color="success" small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import TaskList from '@/components/TaskList.vue';
import TaskSelected from '@/components/TaskSelected.vue';
import RouteInfo from '@/components/RouteInfo.vue';
import Map from '@/components/Map.vue';
import pregQuote from '@/snippets/index.js';

export default {
  name: 'Home',
  components: {
    TaskList,
    TaskSelected,
    RouteInfo,
    Map,
  },
  data() {
    return {
      date: this.$moment().format('YYYY-MM-DD'),
      sortBy: 'priority',
      selected: [],
      userStart: {},
      userDestination: {},
      sheet: false,
      subTasks: [],
      routeInfo: [],
      routeOrder: [],
      routeLink: '',
      optimized: false,
      routeInfoLoading: false,
      savedTasks: [],
    }
  },
  computed: {
    ...mapState(['isAuthorized', 'userToken', 'apiFilters', 'appSearch', 'apiTasks', 'taskColorMap', 'user', 'estimate']),
    unfilteredTasks() {
      // parse raw tasks
      const apiTasks = Object.entries(this.apiTasks).map((el) => {
        return {
          ...el[1]
        };
      });
   //console.log('unfiltered Tasks');
      return this.$lodash.unionBy([...apiTasks, ...this.savedTasks], 'id');
    },
    filteredTasks() {
      //console.log('filteredtasks')
      let tasks = this.unfilteredTasks;
      // sort
      tasks = this.$lodash.orderBy(tasks, this.sortBy, 'asc');
      // filter
      tasks = tasks.filter((el) => {
        // by search string
        const searchStringEscaped = pregQuote(this.appSearch)
        const search = Object.values(el).some((value) => {
            if(value==null){
                value="";
            }
          return !this.appSearch || new RegExp(searchStringEscaped, 'ig').test(value.toString());
        });
        // by group
       // const group = this.apiFilters.priority.includes(el.priority+'');
            //   console.log("search"+search);
              //    console.log("group"+this.apiFilters.priority);

                //  console.log(el.priority);
        return search ;//&& group;
      })
  
      return tasks;
    },
    unselectedTasks() {
      // remove selected
      return this.$lodash.differenceBy(this.filteredTasks, this.selected, 'id');
    },
    tasksGrouped() {
      // tasks grouped by 'location', for map
     // console.log('tasksGrouped');
      return this.$lodash.uniqBy(this.filteredTasks, 'location');
    },
  },
  watch: {
    'user.location': {
      handler() {
        this.userStart = {
          lat: Number.parseFloat(this.user.location?.latitude),
          lng: Number.parseFloat(this.user.location?.longitude),
        };
         
        this.userDestination = this.userStart;
      },
    },
    // selected: {
    //   handler() {
    //     this.calcRoute();
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    optimized() {
      this.calcRoute();
    },
    userStart() {
      this.calcRoute();
    },
    userDestination() {
      this.calcRoute();
    },
  },
  methods: {
    calcRoute() {
      // calc route
     // console.log('selected length: '+this.selected.length);
      if (!this.$refs.map) return;
      this.$refs.map.calcRoute({
        start: this.userStart,
        end: this.userDestination,
        tasks: this.selected,
        optimized: this.optimized,
      })
        .then(({info, order, link}) => {
          this.routeInfo = info;
          this.routeOrder = order;
          this.routeLink = link;
          // if (this.optimized) this.sortSelected()
        });
    },
    sortSelected() {
      let selected = [];
      for (let i = 0; i < this.routeOrder.length; i++) {
        const el = this.routeOrder[i];
        selected.push(this.selected[el]);
      }

           
      this.selected = selected;
    },
    add(task) {
      if (!task) return;
       
      this.selected.push(task);

      this.calcRoute();
    },
    del(taskDel) {
      if (!taskDel) return;
      this.selected = this.selected.filter((task) => task.id !== taskDel.id);

      this.calcRoute();
    },
    toggle(task) {
      if (!task) return;
      const selectedHas = this.selected.some((t) => t.id === task.id);
      if (!selectedHas) {
        /** add */
        this.add(task)
        //console.log(JSON.stringify(task));
      } else {
        this.del(task)
      }
    },
    addLocationTask(){
              this.add({id:-Date.now(),type:"location",edit:true,station:{}});
    },
    panToTask(task) {

      if(task.type && task.type==='location'){

      }else{
      // if many tasks on location
      this.sheet = true;
 
      // get unfiltered tasks grouped by location
      this.subTasks = this.unfilteredTasks.filter((el) => el.location === task.location);
      }
      this.$refs.map.panTo(task);
    }
  },
};
</script>
