<template>
  <v-list two-line outlined dense>
    <v-subheader>Your route for {{ $moment(date).format('DD MMM YYYY') }}</v-subheader>
    <v-list-item>
      <v-list-item-title>Optimized</v-list-item-title>
      <v-list-item-action>
    
        <v-switch :input-value="!!optimized" @change="(val) => $emit('update:optimized', !!val)" inset></v-switch>
      </v-list-item-action>
       <v-list-item-action v-if="false">
          <v-btn icon color="success"  @click.stop="$emit('add-location-task')">
          <v-icon  >mdi-plus</v-icon>
        </v-btn>
 
      </v-list-item-action>
    </v-list-item>
  
    <v-list-item link key="task-selected-start" v-if="!location.start.edit">
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-up</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{start}}</v-list-item-title>
        <v-list-item-subtitle>Start location</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click.stop="$set(location.start, 'edit', !location.start.edit)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item dense v-else>
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-up</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-autocomplete
          :hide-details="true"
          :items="predictions"
          item-text="description"
          :item-value="(item) => item"
          v-model="location.start.prediction"
          :search-input.sync="location.start.search"
          @change="$set(location.start, 'edit', !location.start.edit)"
          autofocus
          :loading="googleApis.loading"
          outlined
          dense
          flat
          label="Start"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-sheet class="caption">
                  Startadresse eingeben, falls Route nicht an der Heimadresse beginnt
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-list-item-content>
    </v-list-item>
    <template v-if="loading">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        v-for="(item, i) in (tasks.length > 0 ? tasks : Array(3))" :key="i"
      ></v-skeleton-loader>
    </template>

    <draggable v-if="!loading" v-model="tasks">
      <v-slide-y-transition group>
        <v-list-item link v-for="(task, i) in tasks" @click="$emit('pan-to', task)" :key="i">
          <v-list-item-avatar size="24">
           
                 <v-icon v-if="task.type == 'location'"  class="grey" size="16" color="white" >mdi-map-marker</v-icon>
            <v-avatar v-else class="white--text" :color="taskColorMap[task.priority]">{{task.priority}}</v-avatar>
          </v-list-item-avatar>
             <v-list-item-content v-if="task.type == 'location' && task.edit">
             <v-autocomplete
          :hide-details="true"
          :items="predictions"
          item-text="description"
          :item-value="(item) => item"
          v-model="task.prediction"
          :search-input.sync="task.search"
          @change="updateTaskLocation(task,task.prediction.place_id);"
          @update:search-input="locationSearch"
          autofocus
          :loading="googleApis.loading"
          outlined
          dense
          flat
          label="Location"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-sheet class="caption">
                  Adresse eingeben 
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
               </v-list-item-content>

                 <v-list-item-content v-if="task.type == 'location' && !task.edit">
            <v-list-item-title>{{task.location}}</v-list-item-title>
                 <v-list-item-subtitle >Location</v-list-item-subtitle>
               </v-list-item-content>
          <v-list-item-content v-if="task.type == null">
            <v-list-item-title :title="task.location">{{task.location}}</v-list-item-title>
            <v-list-item-subtitle :title="task.location">{{task.description}}</v-list-item-subtitle>
          </v-list-item-content>
           <v-list-item-action v-if="task.type == 'location' && !task.edit" >
<v-btn icon @click.stop="$set(task, 'edit', !task.edit)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
           </v-list-item-action>
          <v-list-item-action>
              
            <v-btn color="error" small @click.stop="$emit('del', task)" icon>
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-list-item-action>
          
        </v-list-item>
      </v-slide-y-transition>
    </draggable>

    <v-list-item link key="task-selected-end" v-if="!location.end.edit">
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-down</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{destination}}</v-list-item-title>
        <v-list-item-subtitle>Destination location</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click.stop="$set(location.end, 'edit', !location.end.edit)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item dense v-else>
      <v-list-item-avatar size="24">
        <v-icon class="grey" size="16" color="white" >mdi-map-marker-down</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-autocomplete
          :hide-details="true"
          :items="predictions"
          item-text="description"
          :item-value="(item) => item"
          v-model="location.end.prediction"
          :search-input.sync="location.end.search"
          @change="$set(location.end, 'edit', !location.end.edit)"
          autofocus
          :loading="googleApis.loading"
          outlined
          dense
          flat
          label="End"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-sheet class="caption">
                  Endadresse eingeben, falls Route nicht an der Heimadresse beginnt
                </v-sheet>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapState } from 'vuex';
import places from '@/mixins/places';

export default {
  mixins: [places],
  props: {
    selected: Array,
    optimized: Boolean,
    userStart: {},
    userDestination: null,
    date: String,
    loading: Boolean,
    routeOrder: Array,
  },
  data() {
    return {
      location: {
        start: {
          prediction: null,
          search: '',
          edit: false,
        },
        end: {
          prediction: null,
          search: '',
          edit: false,
        },
      },
    }
  },
  computed: {
    ...mapState(['isAuthorized', 'userToken', 'user', 'taskColorMap']),
    start() {
      return this.location.start?.prediction?.description || this.user.location?.address;
    },
    destination() {
      return this.location.end?.prediction?.description || this.user.location?.address;
    },
    tasks: {
      set(val) {
        this.$emit('update:selected', val);
      },
      get() {
        let selected = [];
        const hasSort = this.routeOrder.length === this.selected.length;
        for (let i = 0; i < this.routeOrder.length; i++) {
          const el = this.routeOrder[i];
          selected.push(this.selected[el]);
        }
        return this.optimized && hasSort ? selected : this.selected;
      }
    },
    predictions() {
      const predictions = this.googleApis.predictions;
      return predictions || [];
    },
  },
  watch: {
    'location.start.search': {
      handler(val) {
        if (val && val.length >= 3) {
          this.searchPlaces(val);
        }
      },
    },
    'location.end.search': {
      handler(val) {
        if (val && val.length >= 3) {
          this.searchPlaces(val);
        }
      },
    },
    'location.start.prediction': {
      handler(val) {
        console.log('watch location.start.prediction')
        this.getLocation(val.place_id)
          .then((res) => {
            console.log('watch location.start.prediction getLocation')
          //  this.userStart = res;
            this.$emit('update:userStart', {lat:res.lat(),lng:res.lng()});
          });
      }
    },
    'location.end.prediction': {
      handler(val) {
        this.getLocation(val.place_id)
          .then((res) => {
            this.$emit('update:userDestination', {lat:res.lat(),lng:res.lng()});
          });
      }
    },
  },
  mounted() {
    this.loadMapScript()
      .then(() => {
        this.initMapPlaces();
      })
  },
  methods:{

    
locationSearch:function(val){
if (val && val.length >= 3) {
          this.searchPlaces(val);
        }
},  updateTaskLocation:function(task,placeId){
   let res=  this.getLocation(placeId)


         .then((res) => {
          // console.log("ffounnndd"+res.lat());
          //  this.userStart = res;
        
             task.station.latitude=res.lat();
          task.station.longitude=res.lng();
          task.location=task.prediction.description;
      task.id=-Date.now();//to trigger save button
          delete task['prediction'];
            delete task['search'];
             task.edit=false;
               this.$emit('calc-route');
           // Vue.set(task, 'edit', !task.edit);
          });
}
  }
  
}
</script>
