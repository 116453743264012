<template>
  <v-list dense max-height="420px" style="overflow-y:auto;">
    <v-subheader>Available tasks</v-subheader>
    <template v-if="loading">
      <v-skeleton-loader
        type="list-item-avatar-two-line"
        v-for="(item, i) in (tasks.length > 0 ? tasks : Array(3))" :key="i"
      ></v-skeleton-loader>
    </template>
    <v-list-item-group v-else>
      <v-slide-y-transition group>
        <v-tooltip
          v-for="(task, i) in tasks"
          :key="i"
          bottom
          max-width="500px"
        >
          <v-row no-gutters class="caption">
            <v-col cols="12 mb-2" v-if="task.description"><v-icon small class="mr-2">mdi-information</v-icon>{{task.description}}</v-col>
            <v-col cols="12 mb-2" v-if="task.location"><v-icon small class="mr-2">mdi-map-marker</v-icon>{{task.location}}</v-col>
            <v-col cols="12 mb-2" v-if="task.comments"><v-icon small class="mr-2">mdi-chat</v-icon>{{task.comments}}</v-col>
          </v-row>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              @click.stop="$emit('pan-to', task)"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-avatar size="24">
                <v-avatar class="white--text" :color="taskColorMap[task.priority]">{{task.priority}}</v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title :title="task.description" >{{task.description}}</v-list-item-title>
                <v-list-item-subtitle :title="task.location" >{{task.location}}</v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-action small>
                <v-btn icon color="success" small @click.stop="$emit('add', task)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-action> -->
            </v-list-item>
          </template>
        </v-tooltip>
        <v-list-item v-if="tasks.length === 0" key="empty-items">
          <v-list-item-title>No available tasks</v-list-item-title>
        </v-list-item>
      </v-slide-y-transition>
    </v-list-item-group>
  </v-list>
</template>
<script>
import { mapState } from 'vuex';
import { getTasksApi } from '@/api';

export default {
  props: {
    tasks: Array,
    userStart:null
  },
  data() {
    return {
      loading: false,
      search: '',
    }
  },
  computed: {
    ...mapState(['isAuthorized', 'userToken', 'apiFilters', 'taskColorMap']),
  },
  watch: {
    isAuthorized: {
      handler() {
      //  console.log('isAuthorized');
        this.getTasks();
      },
      immediate: true,
    },
    apiFilters: {
      handler() {
       //   console.log('Tasklist apifilters');
        this.getTasks();
      }
    },
    userStart: {
      handler() {
        this.getTasks();
      }
    }
  },
  methods: {
    async getTasks() {
      this.loading = true;
      if (this.isAuthorized) {
        const params = {
          ...this.apiFilters,
        }
        if(this.userStart!=null){
 
             params.lat=this.userStart.lat;
             params.lng=this.userStart.lng;
        }
        const res = await getTasksApi(params);

        if (res.data) {
          this.$store.commit('SET_API_TASKS', res.data);

        }
      }
      this.loading = false;
    }
  },
}
</script>
